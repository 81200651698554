<template>
  <div>
    <ResetAllButton :disabled="noLicensing" @click="$emit('reset')" />
  </div>
</template>

<script>
  import ResetAllButton from '@/components/auth/ResetAllButton';

  export default {
    name: 'ResetLicensingButton',
    components: { ResetAllButton },
    props: {
      checkedLicensing: {
        type: Object,
        default: () => ({}),
      },
    },
    emits: ['reset'],
    computed: {
      noLicensing() {
        return Object.values(this.checkedLicensing).every(i => i.length === 0);
      },
    },
  };
</script>
<style scoped>

</style>
