<template>
    <div class="h-full">
        <loader :loading="loading" :backdrop="true"/>
        <List
            v-if="!loading"
            title="Communities"
            resource="customerCommunities"
            class="community-list"
            ref="list"
            routeIdParam="communityId"
            :request-params="{customerId: customerId, nonDeleted: true}"
            :basePath="basePath"
            :fields="fields"
            :sort-order="sortOrder"
            :pageSize="20"
            :on-row-click="rowAction"
            :search="true"
            search-placeholder="Search for a community by name"
            @cell-clicked="handleCellClick"
            :response-mapper="responseMapper"
            :filterClasses="filterClasses"
            infinity-scroll
        >
            <template v-slot:quickfilter="">
                <ResetLicensingButton v-if="isQuextAdmin" :checkedLicensing="checkedCommunities" @reset="resetAll"/>
            </template>
        </List>
        <ModalFooter
            :footer="{primaryButton: isQuextAdmin ? 'save' : null, tertiaryButton: isQuextAdmin ? 'cancel' : null}"
            :primary="save"
            :tertiary="close"
        />
    </div>
</template>

<script>
import { clone, differenceWith, uniq } from 'lodash-es';
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from "@/mixins/NotifyMixin";
import Loader from '@/components/ui/Loader';
import ModalFooter from '@/components/ui/modals/ModalFooter'
import AuthMixin from '@/components/auth/AuthMixin';
import List from '@/components/auth/list/List';
import CheckField from "@/components/auth/list/fields/CheckField";
import ResetLicensingButton from "@/views/auth/customers/communities/ResetLicensingButton";

export default {
    name: 'CommunitiesList',
    components: {
        ResetLicensingButton,
        Loader,
        List,
        ModalFooter,
    },
    mixins: [
        ModalNavigation,
        AuthMixin,
        NotifyMixin,
        ConfirmationMixin,
    ],
    computed: {
        basePath: function () {
            return this.$route.path;
        },
        customerId: function () {
            return this.$route.params.customerId;
        },
        fields() {
            const licensingFields = this.customerLicensing.map((item) => ({
                name: CheckField,
                title: () => `<span class="flex flex-col text-center font-600">
              <span>${item.bundleName}</span>
              <span class="text-sm">${this.checkedCommunities[item.bundleId]?.length || 0}<span class="font-normal"> / ${item.numberOfCommunities}</span></span>
            </span>`,
                titleClass: 'whitespace-no-wrap font-normal',
                switch: {
                    containerClass: 'flex justify-center',
                    fieldName: `licensingBundles[${item.bundleId}]`,
                    onChange: this.toggleCommunity(item.bundleId),
                    onInput: this.onLicensingInput(item.bundleId, item.bundleName),
                    readonly: !this.isQuextAdmin,
                    disabled: (data) =>
                        this.checkedCommunities[item.bundleId]?.length === item.numberOfCommunities
                        && !this.checkedCommunities[item.bundleId].includes(data.id),
                    disabledReason: 'Maximum number of communities is reached',
                    info: ({id, advancedOptions = {}}) =>
                        this.checkedCommunities[item.bundleId].includes(id) && advancedOptions[item.bundleId] ? 'Advanced options' : '',
                },
            }));

            return [
                {
                    name: 'name',
                    title: 'community name',
                    sortField: 'name',
                    dataClass: this.isQuextAdmin ? 'community-link btn-link whitespace-no-wrap' : 'whitespace-no-wrap',
                    titleClass: 'font-normal',
                },
                {
                    name: 'ownerName',
                    title: 'owner name',
                    sortField: 'ownerName',
                    titleClass: 'font-normal whitespace-no-wrap',
                },
                ...licensingFields,
            ];
        },
        rowAction() {
            return this.isQuextAdmin ? 'none' : 'details';
        },
    },
    data: function () {
        return {
            loading: true,
            customerLicensing: [],
            checkedCommunities: {}, // { [bundleId]: [communityIds]}
            customerOptions: {}, // { [bundleId]: [{appId, attributeId, value}] }
            initCheckedCommunities: {},
            sortOrder: [{
                field: 'name',
                direction: 'asc',
            }],
            filterClasses: {
                container: 'relative',
                quickFilters: 'absolute bottom-0 right-0',
                searchContainer: 'w-1/3',
            },
        };
    },
    methods: {
        handleCellClick({data, field}) {
            if (this.isQuextAdmin && field.name === 'name') {
                this.$router.push({
                    name: 'customer.communities.edit',
                    params: {
                        customerId: this.customerId,
                        communityId: data.id,
                    },
                });
            }
        },
        onLicensingInput(bundleId, bundleName) {
            return (value, {data, update}) => {
                if (value) {
                    update(value);
                    return;
                }

                const checkedOnInit = Boolean(this.initCheckedCommunities[bundleId]?.includes(data.id));

                if (checkedOnInit) {
                    this.requestConfirmation({
                        confirmationMessage: `You’re removing license of ${bundleName} for ${data.name} community. After saving changes this will lead to revocation of access for staff users in all customer communities. The action can’t be undone. Restoration of access will have to be made by admin manually for each staff user.`,
                        confirmBtnText: 'proceed with revoking',
                        cancelBtnText: 'cancel',
                        confirmationType: 'warning',
                    }).then((confirmed) => {
                        if (confirmed) {
                            update(value);
                        }
                    });
                } else {
                    update(value);
                }
            }
        },
        toggleCommunity(bundleId) {
            return (value, {data}) => {
                this.checkedCommunities[bundleId] = value
                    ? uniq([...this.checkedCommunities[bundleId], data.id])
                    : this.checkedCommunities[bundleId].filter(c => c !== data.id);
            }
        },
        resetAll() {
            Object.keys(this.checkedCommunities).forEach((key) => {
                this.checkedCommunities[key] = [];
            });
            this.$refs.list?.reload();
        },
        save() {
            this.$authDataProvider.update('customerLicensing', {
                customerId: this.customerId,
                data: {communities: this.checkedCommunities},
            })
                .then(() => {
                    this.notifySuccess('Community licensing was saved successfully');
                })
                .catch(error => this.notifyError(error.message));
        },
        responseMapper(resp) {
            return {
                ...resp,
                content: resp.content.map(row => ({
                    ...row,
                    licensingBundles: this.customerLicensing.reduce((acc, {bundleId}) => {
                        acc[bundleId] = this.checkedCommunities[bundleId]?.includes(row.id);
                        return acc;
                    }, {}),
                    advancedOptions: row.licenseBindings?.reduce((acc, {bundleId, attributes = []}) => {
                        // if community options differ from customer options, 'advancedOptions' is true
                        acc[bundleId] = attributes.length !== this.customerOptions?.[bundleId].length
                            || differenceWith(
                                attributes,
                                this.customerOptions?.[bundleId],
                                (a, b) => a?.attributeId === b?.attributeId && a?.value === b?.value,
                            ).length > 0;
                        return acc;
                    }, {}) || {},
                })),
            }
        },
    },
    mounted() {
        this.$authDataProvider.getOne('customers', {id: this.customerId})
            .then((response) => {
                this.customerLicensing = response?.licenses || [];
                const {checkedCommunities, customerOptions} = this.customerLicensing.reduce((acc, item) => {
                    acc.checkedCommunities[item.bundleId] = item?.communities?.map(c => c.communityId) || [];
                    acc.customerOptions[item.bundleId] = item?.options || [];
                    return acc;
                }, {checkedCommunities: {}, customerOptions: {}});

                this.checkedCommunities = checkedCommunities;
                this.initCheckedCommunities = clone(checkedCommunities);
                this.customerOptions = customerOptions;
                this.loading = false;
            })
            .catch(error => this.notifyError(error.message))
    },
};
</script>
<style scoped>
.community-list :deep(.vuetable th:first-child),
.community-list :deep(.vuetable td:first-child) {
    position: sticky;
    left: 0;
    z-index: 11;
}

.community-list :deep(.vuetable td:first-child:not(.vuetable-empty-result)) {
    background-color: inherit;
    z-index: 10;
}

.community-list :deep(.vuetable th) {
    top: -1px;
}

.community-list :deep(.vuetable-td-name:hover) {
    text-decoration: none;
}

.community-list :deep(.community-link) {
    font-size: inherit;
    font-family: inherit;
}
</style>
